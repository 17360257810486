import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import ContentContainer from "../components/ContentContainer";

const Gdpr = ({ pageContext }) => (
	<Layout pageContext={pageContext}  >
		<SEO
			title={pageContext.GDPR_META_TITLE}
			description={pageContext.GDPR_META_DESCRIPTION}
			lang={pageContext.lang}
		/>
		<ContentContainer>
			<h1>{pageContext.GDPR_HEADLINE}</h1>
			<p>{pageContext.GDPR_BLOCK_1}</p>
			<ol>
				<li>
					<p>{pageContext.GDPR_BLOCK_2}</p>
					<ul>
						<li>{pageContext.GDPR_BLOCK_3}</li>
						<li>{pageContext.GDPR_BLOCK_4}</li>
						<li>{pageContext.GDPR_BLOCK_5}</li>
					</ul>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_6}</p>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_7}</p>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_8}</p>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_9}</p>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_10}</p>
				</li>
				<li>
					<p>{pageContext.GDPR_BLOCK_11}</p>
					<ul>
						<li>{pageContext.GDPR_BLOCK_12}</li>
						<li>{pageContext.GDPR_BLOCK_13}</li>
						<li>{pageContext.GDPR_BLOCK_14}</li>
						<li>{pageContext.GDPR_BLOCK_15}</li>
						<li>{pageContext.GDPR_BLOCK_16}</li>
					</ul>
				</li>
			</ol>
		</ContentContainer>
	</Layout>
);

Gdpr.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};

export default Gdpr;
